import firebase from 'firebase/app'

export default class GetSigleDataByDocID {
  constructor() {
    this.dic = {}
  }

  async fetch(payload) {
    const { collection, docID } = payload
    try {
      await firebase.firestore().collection(collection).doc(docID)
        .get()
        .then(doc => {
          const data = doc.data()
          if (data) { this.dic = data }
        })

      const response = {
        status: 'success',
        data: this.dic,
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
