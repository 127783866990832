<template>
  <div class="space_">
    <Alert
      :user-dic="userDic"
      @refetch="refetch"
    />
    <Flow
      :user-dic="userDic"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// import {
//   BButton, BAlert, BImg, BAvatar,
// } from 'bootstrap-vue'
import Alert from '@/components/jobSeeker/dashboad/Alert.vue'
import Flow from '@/components/jobSeeker/dashboad/Flow.vue'
import useJobSeeker from '@/components/js/compositionAPI/useJobSeeker'

export default {
  components: {
    Flow,
    Alert,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      userDic,
      refetch,
    } = useJobSeeker()

    return {
      userDic,
      refetch,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
.imgStyle_ {
  width: 110%;
  height: 200px;
  object-fit: cover;
  margin: 0% -5% 0 -5%;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .imgStyle_ {
    width: 110%;
    height: 360px;
    object-fit: cover;
    margin: -5% -5% 0 -5%;
  }
}
</style>
