<template>
  <div class="">
    <b-img
      src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2FF4CA6EAD-2CD1-4585-9D27-AC0F9B844B95.jpeg?alt=media&token=c119cbeb-5da3-4064-97da-8fe249550c04"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    />
    <b-alert
      variant="secondary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="50"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
              variant="light-primary"
            />
          </div>
          <div class="">
            <b>ようこそ、転コレへ！</b>転職前は不安がいっぱいですが、私たちが最後までサポートします。ぴったりのお仕事が必ず見つかりますよ！
          </div>
        </div>
      </div>
    </b-alert>
    <b-alert
      v-if="!userDic.isUserProfile"
      variant="success"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="50"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
              variant="light-primary"
            />
          </div>
          <div class="">
            <b>プロフィール</b>
            <feather-icon
              icon="Edit3Icon"
              class="ml-50"
            />
            をご入力頂くと、お仕事のご紹介が始まります！
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="success"
            class="btn-wishlist"
            size="sm"
            @click="isShowModalProfile = true"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            />
            <span>登録する</span>
          </b-button>
        </div>
      </div>
    </b-alert>
    <b-alert
      v-if="!userDic.isUserHearing"
      variant="primary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="50"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
              variant="light-primary"
            />
          </div>
          <div class="">
            <b>アンケート</b>
            <feather-icon
              icon="EditIcon"
              class="ml-50"
            />
            もご入力頂くと、より希望に沿ったお仕事をご紹介できますよ！
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="primary"
            class="btn-wishlist"
            size="sm"
            @click="isShowModal = true"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            />
            <span>回答する</span>
          </b-button>
        </div>
      </div>
    </b-alert>
    <b-modal
      v-model="isShowModalProfile"
      size="lg"
      ok-only
      ok-title="閉じる"
    >
      <EditProfile
        @childevent="registeredPfofile"
        @openHearingEditor="openHearingEditor"
      />
    </b-modal>
    <b-modal
      v-model="isShowModal"
      size="lg"
      ok-only
      ok-title="閉じる"
    >
      <EditHearing
        @childevent="registered"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BAlert, BImg, BAvatar,
} from 'bootstrap-vue'
import EditHearing from '@/components/jobSeeker/hearing/EditHearing.vue'
import EditProfile from '@/components/jobSeeker/profile/EditProfile.vue'

export default {
  components: {
    BButton,
    BAlert,
    BImg,
    BAvatar,
    EditHearing,
    EditProfile,
  },
  directives: {
    Ripple,
  },
  props: {
    userDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowModal: false,
      isShowModalProfile: false,
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
    registeredPfofile() {
      this.$emit('refetch')
      this.isShowModalProfile = false
    },
    registered() {
      this.$emit('refetch')
      this.isShowModal = false
    },
    openHearingEditor() {
      this.isShowModal = true
    },
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
.imgStyle_ {
  width: 110%;
  height: 200px;
  object-fit: cover;
  margin: 0% -5% 0 -5%;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .imgStyle_ {
    width: 110%;
    height: 360px;
    object-fit: cover;
    margin: -4% -5% 0 -5%;
  }
}
</style>
