<template>
  <div class="">
    <Dashboad />
  </div>
</template>

<script>
import Dashboad from '@/components/jobSeeker/dashboad/Dashboad.vue'

export default {
  components: {
    Dashboad,
    // AppealCard,
  },
}
</script>
