<template>
  <div class="mt-4">
    <h3>
      <b-avatar
        size="26"
        variant="primary"
      >
        ・
      </b-avatar>
      転職までの流れ
    </h3>
    <div class="lp-card-space">
      <b-card>
        <app-timeline>
          <app-timeline-item
            icon="Edit3Icon"
            variant="success"
            class="mt-2"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div class="lp-card-title-mid mb-2">
                <b>プロフィール入力</b>
              </div>
              <small class="text-muted">5分で完了！</small>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <b-avatar
                  size="50"
                  src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
                  variant="light-primary"
                />
              </div>
              <div class="">
                簡単なプロフィールに入力しましょう。入力が終わると転コレからご連絡を差し上げます。
              </div>
            </div>
            <div class="text-right mt-1">
              <div
                v-if="!userDic.isUserProfile"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="success"
                  class=""
                  @click="move('profile')"
                >
                  <feather-icon
                    icon="UploadCloudIcon"
                    class="mr-50"
                  />
                  登録する
                </b-button>
              </div>
              <div
                v-else
                class="text-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                登録完了！
              </div>
            </div>
          </app-timeline-item>
          <app-timeline-item
            icon="EditIcon"
            variant="primary"
            class=""
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div class="lp-card-title-mid mb-2">
                <b>アンケート回答</b>
              </div>
              <small class="text-muted">3分で完了！</small>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <b-avatar
                  size="50"
                  src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
                  variant="light-primary"
                />
              </div>
              <div class="">
                ｢どんなお仕事に興味がありますか？｣あなたのご希望をお聞かせください！ヒアリングの前に教えて頂ければ、より具体的にご提案させて頂きます。
              </div>
            </div>
            <div class="text-right mt-1">
              <div
                v-if="!userDic.isUserHearing"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="primary"
                  class=""
                  @click="move('hearing')"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50"
                  />
                  回答する
                </b-button>
              </div>
              <div
                v-else
                class="text-primary"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                登録完了！
              </div>
            </div>
          </app-timeline-item>
          <app-timeline-item
            icon="SmileIcon"
            variant="danger"
            class=""
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div class="lp-card-title-mid mb-2">
                <b>ヒアリング</b>
              </div>
              <small class="text-muted">詳しく聞かせて下さい！</small>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <b-avatar
                  size="50"
                  src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
                  variant="light-primary"
                />
              </div>
              <div class="">
                ｢どんなお仕事に興味がありますか？｣あなたのご希望をお聞かせください！ヒアリングの前に教えて頂ければ、より具体的にご提案させて頂きます。
              </div>
            </div>
          </app-timeline-item>
          <app-timeline-item
            icon="UsersIcon"
            variant="info"
            class=""
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div class="lp-card-title-mid mb-2">
                <b>ご希望の会社と面接</b>
              </div>
              <small class="text-muted">いよいよです！</small>
            </div>
            <p class="lp-card-text-mid">
              ご希望の会社が決まったら面接の準備をしましょう！面接は誰でも不安ですが、ご心配なく！転コレエージェントがしっかりとフォローします！
            </p>
            <p class="lp-card-text-mid">
              あなたの良さを最大限アピールしましょう。
            </p>
          </app-timeline-item>
          <app-timeline-item
            icon="BellIcon"
            variant="secondary"
            class=""
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div class="lp-card-title-mid mb-2">
                <b>結果のご連絡</b>
              </div>
              <small class="text-muted">いよいよです！</small>
            </div>
            <p class="lp-card-text-mid">
              面接の結果をお伝えします。ご希望の会社から採用されると、新しい生活が始まります！
            </p>
            <p class="lp-card-text-mid">
              万が一、その会社とご縁が無くても安心してください。魅力的な会社はたくさんあります。また一緒に探して面接に行きましょう！
            </p>
          </app-timeline-item>
        </app-timeline>
        <!-- <div class="m-2 descriptionText">
          <small>※オンラインサービスを実施するためは、パソコンなどの通信機器、skypeやZOOMなどの通話ソフトウェアまたはアプリ、円滑な通信環境のためのイヤホン、マイク、Webカメラ、安定したインターネット環境（wifiなど）をご自身でご準備いただく必要があります。</small>
        </div> -->
      </b-card>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BAvatar, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    userDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.explainText {
  font-size: 1.1rem;
}
.descriptionText {
  color: rgb(184, 184, 184);
}
</style>
