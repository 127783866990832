import { ref, onMounted } from '@vue/composition-api'
import GetSigleDataByDocID from '@/firestore/data/GetSigleDataByDocID'
import store from '@/store'

export default function useJobSeeker() {
  const userDic = ref({})

  const getUserData = async () => {
    const d = {
      collection: 'user',
      docID: store.state.userBasic.uid,
    }
    const sigleDataByDocID = new GetSigleDataByDocID()
    const res = await sigleDataByDocID.fetch(d)
    return res.data
  }
  const fetch = async () => {
    userDic.value = await getUserData()
  }
  const refetch = () => {
    window.console.log('🐶 refetch')
    fetch()
  }
  onMounted(() => {
    fetch()
  })
  return {
    userDic,
    fetch,
    refetch,
  }
}
